<template>
    <div class="card">
        <h5 class="card-header bg-primary text-white">
            <i class="pi pi-users"></i> Current Roles
        </h5>
        <div class="card-body">
            <p class="card-text">
                Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                Inventore sed consequuntur error repudiandae numquam deserunt
                quisquam repellat libero asperiores earum nam nobis, culpa
                ratione quam perferendis esse, cupiditate neque quas!
            </p>
            <ul class="list-group list-group-flush">
                <li
                    v-for="contact in account.contactRoles"
                    :key="contact.contact"
                    class="list-group-item"
                >
                    <span
                        class="badge bg-light text-dark"
                        style="min-width: 150px; margin-right: 20px;"
                        >{{ contact._new_contactroletype_value }}</span
                    >
                    {{ contact.new_person }}
                    <alert-status
                        v-bind:text="contact.statuscode_value"
                        v-bind:class-object="{
                            'float-end': true
                        }"
                    />
                </li>
                <li class="list-group-item">
                    <div class="d-grid gap-2">
                        <button type="button" class="btn btn-outline-primary">
                            <i class="fa fa-plus" aria-hidden="true"></i>
                            Add a new User/Role
                        </button>
                    </div>
                </li>
            </ul>
        </div>
    </div>
</template>

<script lang="js">

export default {
    name: 'ContactRolesPanel',
    data: () => {
        return {
        }
    },
    props: {
        account: {type: Object, required: true}
    }
}
</script>
<style scoped lang="scss"></style>
