<template>
    <div class="card">
        <h5 class="card-header bg-primary text-white">
            <i class="pi pi-bookmark"></i> Business Scopes
        </h5>
        <div class="card-body">
            <p class="card-text">
                Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                Inventore sed consequuntur error repudiandae numquam deserunt
                quisquam repellat libero asperiores earum nam nobis, culpa
                ratione quam perferendis esse, cupiditate neque quas!
            </p>
            <ul class="list-group list-group-flush">
                <li
                    v-for="scope in scopes"
                    :key="scope._new_certifiedbusinessscopetype"
                    class="list-group-item"
                >
                    {{ scope._new_certifiedbusinessscopetype_value }}
                    <alert-status
                        v-bind:text="scope.statuscode_value"
                        v-bind:class-object="{
                            'float-end': true
                        }"
                    />
                </li>

                <li class="list-group-item">
                    <div class="d-grid gap-2">
                        <button
                            type="button"
                            class="btn btn btn-outline-primary"
                        >
                            <i class="fa fa-plus" aria-hidden="true"></i>
                            Add a Business Scope
                        </button>
                    </div>
                </li>
            </ul>
        </div>
    </div>
</template>

<script lang="js">

export default {
    name: 'BusinessScopesPanel',
    data: () => {
        return {
        }
    },
    methods: {
        defineStatusClass(value) {
        if (value && value == 'Active') {
            return 'success'
        }
        return 'warn'
        }
    },
    props: {
        scopes: {type: Array, required: true}
    }
}
</script>
<style scoped lang="scss">
.card-header {
    background-color: ivory;
}
</style>
