<template>
    <bread-crumb-panel v-bind:items="[{ label: 'Account' }]" />
    <div v-if="loading">
        <div class="card">
            <div class="card-body">
                <h5 class="card-title"><Skeleton height="2rem" /></h5>
                <p>
                    <Skeleton height="10rem" />
                </p>
            </div>
        </div>
    </div>
    <div v-else>
        <div class="row">
            <div class="card">
                <div class="card-body">
                    <h2>
                        {{ account.new_businessname || 'No Name!' }}

                        <alert-status
                            v-bind:text="account.statuscode_value"
                            v-bind:class-object="{
                                'float-end': true
                            }"
                        />
                    </h2>

                    <p>
                        Lorem ipsum dolor sit amet, consectetur adipisicing
                        elit. Inventore sed consequuntur error repudiandae
                        numquam deserunt quisquam repellat libero asperiores
                        earum nam nobis, culpa ratione quam perferendis esse,
                        cupiditate neque quas!
                    </p>
                </div>
            </div>
        </div>

        <business-scopes-panel v-bind:scopes="account.certifiedBusinesScopes" />
        <contact-roles-panel v-bind:account="account" />
        <cases-panel v-bind:account="account" />
    </div>
</template>

<script lang="js">

import ContactRolesPanel from '@/components/ContactRolesPanel'
import BusinessScopesPanel from '@/components/BusinessScopesPanel'
import CasesPanel from '@/components/CasesPanel'

export default {
    name: 'AccountView',
    data: () => {
        return {
            account: null
        }
    },
    components: {ContactRolesPanel, BusinessScopesPanel, CasesPanel},
    computed: {
        loading () {
            return (this.account) ? false : true
        }
    },
    methods: {
        getStatusSeverity(value) {
        if (value && value == 'Active') {
            return 'success'
        }
        return 'warn'
        }
    },
    async created() {
        this.account = await this.$store.dispatch('retrieveAccount', {id: this.$route.params.id})
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
