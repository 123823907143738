<template>
    <div class="card">
        <h5 class="card-header bg-primary text-white">
            <i class="pi pi-book"></i> Cases
        </h5>
        <div class="card-body">
            <p class="card-text">
                TODO Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                Inventore sed consequuntur error repudiandae numquam deserunt
                quisquam repellat libero asperiores earum nam nobis, culpa
                ratione quam perferendis esse, cupiditate neque quas!
            </p>
            <ul class="list-group list-group-flush">
                <Skeleton /><br />
                <Skeleton /><br />
                <Skeleton /><br />
            </ul>
        </div>
    </div>
</template>

<script lang="js">

export default {
    name: 'CasesPanel',
    data: () => {
        return {
        }
    },
    props: {
        account: {type: Object, required: true}
    }
}
</script>
<style scoped lang="scss"></style>
